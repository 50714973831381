/**
 * Download according to the background interface file stream
 * @param {*} data 数据流
 * @param {*} filename 文件名称
 * @param {*} mime  类型
 * @param {*} bom 数据
 */
export function downloadByData({ data, filename, mime, bom }) {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}

/**
 * Base64转Blob
 * @param dataURL   {String}  base64
 * @param mimeType    {String}  [可选]文件类型，默认为base64中的类型
 * @returns {File}
 */
export function base64ToBlob(dataURL, mimeType = null) {
  const arr = dataURL.split(','),
      defaultMimeType = arr[0].match(/:(.*?);/)[1],
      bStr = atob(arr[1]),
      u8arr = new Uint8Array(bStr.length)
  let n = bStr.length
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n)
  }
  return new Blob([u8arr], {type: mimeType || defaultMimeType})
}